<template>
    <section class="px-5 print-container">
        <img src="/assets/imgs/logo.png" class="img-fluid my-4" alt="Convo4u Logo" width="200" /><br>

        <div v-if="report != null">
            <div>
                <span>Relatório gerado em: {{ report.created_at }}</span> <br>
                <span>Por: {{ report.created_by }}</span>
                <hr>
            </div>

            <section class="row">
                <div
                    v-for="(chart) in report.charts"
                    :key="chart.id"
                    class="col-12 col-md-6 col-lg-4"
                >
                    <chart
                        :value="chart"
                        read-only
                    />
                </div>
            </section>

            <button @click="print" class="float-right btn btn-primary mt-3">
                Baixar PDF
            </button>
        </div>
        <span v-else class="text-center">Não foi possível carregar o relatório</span>
    </section>
</template>

<script>
import Chart from './components/Chart.vue';

export default {
    components: {
        Chart,
    },
    data() {
        return {
            report: null
        };
    },
    created() {
        try {
            this.report = this.$store.state.report.saved;
        } catch (error) {
            this.report = null;
        }
    },
    methods: {
        print() {
            document.querySelector('.chartjs-size-monitor-expand > div').style.display = 'none';
            window.print();
        },
    }
}
</script>
